import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const passiveStyle = {
    color: `gray`,
    textDecoration: `none`,
}

const activeStyle = {
    color: `white`,
    textDecoration: `none`,
}

const HeaderLink = ({name, to}) => (
    <Link to={to}
          style={passiveStyle}
          activeStyle={activeStyle}>
        {name}
    </Link>
)

const Header = ({siteTitle}) => (
    <header
        style={{
            background: `rebeccapurple`,
            marginBottom: `1.45rem`,
        }}
    >
        <div
            style={{
                margin: `0 auto`,
                maxWidth: 960,
                padding: `1.45rem 1.0875rem`,
            }}
        >
            <HeaderLink name={siteTitle} to="/"/>
            |
            <HeaderLink name="Contact" to="/contact"/>
            |
            <HeaderLink name="About" to="/about"/>
            |
            <HeaderLink name="Privacy" to="/privacy"/>
        </div>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header